import React from 'react'
import styled from 'react-emotion'

const StyledHover = styled('div')`
  position: absolute;
  z-index: 1;
  top: -100000px;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.75s;
`

class HoverLink extends React.Component {
  render() {
    const { className, innerRef } = this.props
    //const root = $(innerRef).closest('.block');

    return (
      <StyledHover className={className} innerRef={innerRef}>
        <a className='hover-link' href='#'>Test Link Hover</a>
      </StyledHover>
    )
  }
}

export default function LinkHover(options) {
  const linkHoverState = new LinkHoverState(options);
  return {
    renderEditor: linkHoverState.renderEditor,
    onChange: linkHoverState.onChange
  }
}

class LinkHoverState {
  constructor(options) {
    this.options = options
    this.currentLink = null

    this.renderEditor = this.renderEditor.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  renderEditor(props, editor) { // renderEditor signature changes in v 0.19/0.42
    //console.log(next)
    const children = props.children

    return (
      <React.Fragment>
        {children}
        <HoverLink innerRef={linkHover => (this.linkHover = linkHover)} editor={editor} />
      </React.Fragment>
    )
  }

  onChange({ value }) {
    const link = value.inlines.find(inline => inline.type == 'link')

    this.updateLinkHover(this.linkHover, link, value)
  }

  updateLinkHover (linkHover, link, value) {
    if (!linkHover) return

    if (link && value) {
      const { fragment, selection } = value
      this.currentLink = link

      const href = link.data.get('href')
      const anchorTag = linkHover.firstElementChild
      anchorTag.innerHTML = href
      anchorTag.setAttribute('href', href)

      const native = window.getSelection()
      const range = native.getRangeAt(0)
      const rect = range.getBoundingClientRect()
      const container = range.commonAncestorContainer
      const block = XHR(container).closest('.block')[ 0 ]
      const blockRect = block.getBoundingClientRect()
      linkHover.style.opacity = 1
      linkHover.style.top = `${rect.bottom - blockRect.top + 10}px`

      linkHover.style.left = `${rect.left - blockRect.left +
        rect.width / 2}px`
    } else {
      this.currentLink = null
      linkHover.style.top = `-100000px`
      linkHover.style.left = `-100000px`
      linkHover.style.opacity = 0
    }

  }
}
