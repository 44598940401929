var React = require('react');
import RichEditor from '../slate/rich_editor'
import { Value, KeyUtils } from 'slate';

KeyUtils.setGenerator(function () {
  return Math.floor(Math.random() * Math.floor(4000000000)).toString();
});

const initialValue = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: '',
              },
            ],
          },
        ],
      },
    ],
  },
};

class EditorBlock extends React.Component {
  constructor(props) {
    super(props);

    this.data = this.props.block.instance.data &&
      this.props.block.instance.data[0] &&
      this.props.block.instance.data[0].content;
    var value = JSON.parse(this.data || JSON.stringify(initialValue));

    this.state = {
      value: Value.fromJSON(value)
    }
  }

  // Render the editor.
  render() {
    return <RichEditor
      block={this.props.block}
      initialValue={this.state.value}
      update={this.props.update}
      edit={this.props.edit}
      section={this.props.section} />
  }
}

export default EditorBlock;
